<template>
  <div style="background-color:white">
    <v-row no-gutters>
      <v-col cols="12" class="px-1 d-print-none">
        <v-container class="py-0">
          <v-row no-gutters>
            <v-col cols="12" class="text-right hidden-print-only">
              <v-btn depressed color="primary" class="mt-1 mr-5" @click="printSaleVoucher">
                <v-icon>print</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <img :src="file" ref="cropper" style="height: 15vh;" />
            </v-col>
            <v-col cols="8" class="text-center pt-2">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="display-2 first-heading-font heading-font"
                >{{company.companyName}}</v-col>
                <v-col cols="12" class="headline font-weight-bold">
                  <span class="heading-font">TRAVELS & TOURS</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="vertical-divider"></div>
            </v-col>
            <v-col cols="12" class="text-center font-weight-bold title">"CASHBACK VOUCHER"</v-col>
            <v-col cols="6" class="text-left font-weight-bold">
              <v-row no-gutters>
                <v-col cols="1">To :</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">{{voucher.toCustomer.customerName}}</span>
                </v-col>
                <v-col cols="1">Tel:</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">{{voucher.toCustomer.phone}}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-right font-weight-bold">
              <v-row no-gutters>
                <v-col cols="8">Date :</v-col>
                <v-col cols="4" class="text-center underlineVoucher">{{voucher.date | voucherPrintDate}}</v-col>
                <v-col cols="8">Vr No :</v-col>
                <v-col cols="4" class="underlineVoucher text-center">{{voucher.voucherCode}}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="body-2 text-center px-2">
              <table cellspacing="4" class="simpleTable">
                <thead>
                  <tr>
                    <th style="width: 100px; height: 25px;">No.</th>
                    <th style="width: 475px">Particular</th>
                    <th style="width: 152px">Qty</th>
                    <th style="width: 90px">Price</th>
                    <th style="width: 80px">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 115px; height: 50px">1</td>
                    <td style="width: 600px" class="text-left">Cashback for {{voucher.pnr}}</td>
                    <td
                      style="width: 150px"
                      class="text-right"
                    >{{voucher.way[0].pass| perssengerCount}}</td>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.mmkCashback| defaultPaymentFormat}}</td>
                    <td
                      v-else
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.usdCashback| defaultPaymentWithDollarSignFormat}}</td>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.mmkCashback| defaultPaymentFormat}}</td>
                    <td
                      v-else
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.usdCashback| defaultPaymentWithDollarSignFormat}}</td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th rowspan="2" class="noBorder">Remark :</th>
                    <th rowspan="2" colspan="2" class="text-left noBorder">
                      <span class="underlineVoucher">{{voucher.remark}}</span>
                    </th>
                    <th style="width: 196px; height: 35px">Total</th>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 120px;height: 35px"
                      class="text-right"
                    >{{voucher.mmkCashback | defaultPaymentFormat}}</td>
                    <td
                      v-else
                      class="text-right"
                      style="width: 120px;height: 35px"
                    >{{voucher.usdCashback | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col cols="4" offset="8" class="text-center mt-7">
              <span class="vertical-dotted-divider">Claimant's Signature/ Name</span>
            </v-col>
            <v-col cols="4" class="caption" v-html="company.address"></v-col>
          <v-col cols="3" class="caption pl-4" v-html="'Ph: '+company.phoneNo"></v-col>
          <v-col cols="5" class="text-left caption">
            Email: {{company.email}}
            <br />
            {{company.website}}
          </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="6" class="px-1 d-none d-print-block">
          <v-row no-gutters>
            <v-col cols="12" class="text-right hidden-print-only">
              <v-btn depressed color="primary" class="mt-1 mr-5" @click="printSaleVoucher">
                <v-icon>print</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <img :src="file" ref="cropper" style="height: 15vh;" />
            </v-col>
            <v-col cols="8" class="text-center pt-2">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="display-1 first-heading-font heading-font"
                >{{company.companyName}}</v-col>
                <v-col cols="12" class="headline font-weight-bold">
                  <span class="heading-font">TRAVELS & TOURS</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="vertical-divider"></div>
            </v-col>
            <v-col cols="12" class="text-center font-weight-bold title">"CASHBACK VOUCHER"</v-col>
            <v-col cols="6" class="text-left font-weight-bold">
              <v-row no-gutters>
                <v-col cols="1">To :</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">{{voucher.toCustomer.customerName}}</span>
                </v-col>
                <v-col cols="1">Tel:</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">{{voucher.toCustomer.phone}}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-right font-weight-bold">
              <v-row no-gutters>
                <v-col cols="8">Date :</v-col>
                <v-col cols="4" class="text-center underlineVoucher">{{voucher.date | voucherPrintDate}}</v-col>
                <v-col cols="8">Vr No :</v-col>
                <v-col cols="4" class="underlineVoucher text-center">{{voucher.voucherCode}}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="body-2 text-center px-2">
              <table cellspacing="4" class="simpleTable">
                <thead>
                  <tr>
                    <th style="width: 100px; height: 25px;">No.</th>
                    <th style="width: 475px">Particular</th>
                    <th style="width: 152px">Qty</th>
                    <th style="width: 90px">Price</th>
                    <th style="width: 80px">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 115px; height: 50px">1</td>
                    <td style="width: 600px" class="text-left">Cashback for {{voucher.pnr}}</td>
                    <td
                      style="width: 150px"
                      class="text-right"
                    >{{voucher.way[0].pass| perssengerCount}}</td>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.mmkCashback| defaultPaymentFormat}}</td>
                    <td
                      v-else
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.usdCashback| defaultPaymentWithDollarSignFormat}}</td>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.mmkCashback| defaultPaymentFormat}}</td>
                    <td
                      v-else
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.usdCashback| defaultPaymentWithDollarSignFormat}}</td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th rowspan="2" class="noBorder">Remark :</th>
                    <th rowspan="2" colspan="2" class="text-left noBorder">
                      <span class="underlineVoucher">{{voucher.remark}}</span>
                    </th>
                    <th style="width: 196px; height: 35px">Total</th>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 120px;height: 35px"
                      class="text-right"
                    >{{voucher.mmkCashback | defaultPaymentFormat}}</td>
                    <td
                      v-else
                      class="text-right"
                      style="width: 120px;height: 35px"
                    >{{voucher.usdCashback | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col cols="4" offset="8" class="text-center mt-7">
              <span class="vertical-dotted-divider">Claimant's Signature/ Name</span>
            </v-col>
            <v-col cols="4" class="caption" v-html="company.address"></v-col>
          <v-col cols="3" class="caption pl-4" v-html="'Ph: '+company.phoneNo"></v-col>
          <v-col cols="5" class="text-left caption">
            Email: {{company.email}}
            <br />
            {{company.website}}
          </v-col>
          </v-row>
      </v-col>
      <v-col cols="6" class="px-1 d-none d-print-block hori-dotted-divider">
          <v-row no-gutters>
            <v-col cols="12" class="text-right hidden-print-only">
              <v-btn depressed color="primary" class="mt-1 mr-5" @click="printSaleVoucher">
                <v-icon>print</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <img :src="file" ref="cropper" style="height: 15vh;" />
            </v-col>
            <v-col cols="8" class="text-center pt-2">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="display-1 first-heading-font heading-font"
                >{{company.companyName}}</v-col>
                <v-col cols="12" class="headline font-weight-bold">
                  <span class="heading-font">TRAVELS & TOURS</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="vertical-divider"></div>
            </v-col>
            <v-col cols="12" class="text-center font-weight-bold title">"CASHBACK VOUCHER"</v-col>
            <v-col cols="6" class="text-left font-weight-bold">
              <v-row no-gutters>
                <v-col cols="1">To :</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">{{voucher.toCustomer.customerName}}</span>
                </v-col>
                <v-col cols="1">Tel:</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">{{voucher.toCustomer.phone}}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-right font-weight-bold">
              <v-row no-gutters>
                <v-col cols="8">Date :</v-col>
                <v-col cols="4" class="text-center underlineVoucher">{{voucher.date | voucherPrintDate}}</v-col>
                <v-col cols="8">Vr No :</v-col>
                <v-col cols="4" class="underlineVoucher text-center">{{voucher.voucherCode}}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="body-2 text-center px-2">
              <table cellspacing="4" class="simpleTable">
                <thead>
                  <tr>
                    <th style="width: 100px; height: 25px;">No.</th>
                    <th style="width: 475px">Particular</th>
                    <th style="width: 152px">Qty</th>
                    <th style="width: 90px">Price</th>
                    <th style="width: 80px">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 115px; height: 50px">1</td>
                    <td style="width: 600px" class="text-left">Cashback</td>
                    <td
                      style="width: 150px"
                      class="text-right"
                    >{{voucher.way[0].pass| perssengerCount}}</td>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.mmkCashback| defaultPaymentFormat}}</td>
                    <td
                      v-else
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.usdCashback| defaultPaymentWithDollarSignFormat}}</td>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.mmkCashback| defaultPaymentFormat}}</td>
                    <td
                      v-else
                      style="width: 92px"
                      class="text-right"
                    >{{voucher.usdCashback| defaultPaymentWithDollarSignFormat}}</td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 155px; height: 50px"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th rowspan="2" class="noBorder">Remark :</th>
                    <th rowspan="2" colspan="2" class="text-left noBorder">
                      <span class="underlineVoucher">{{voucher.remark}}</span>
                    </th>
                    <th style="width: 196px; height: 35px">Total</th>
                    <td
                      v-if="voucher.mmkCashback > 0"
                      style="width: 120px;height: 35px"
                      class="text-right"
                    >{{voucher.mmkCashback | defaultPaymentFormat}}</td>
                    <td
                      v-else
                      class="text-right"
                      style="width: 120px;height: 35px"
                    >{{voucher.usdCashback | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col cols="4" offset="8" class="text-center mt-7">
              <span class="vertical-dotted-divider">Claimant's Signature/ Name</span>
            </v-col>
            <v-col cols="4" class="caption" v-html="company.address"></v-col>
          <v-col cols="3" class="caption pl-4" v-html="'Ph: '+company.phoneNo"></v-col>
          <v-col cols="5" class="text-left caption">
            Email: {{company.email}}
            <br />
            {{company.website}}
          </v-col>
          </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import companyInfoService from "../service/CompanyInfoService";
import voucherService from "../service/VoucherService";
import axios from "../config";
export default {
  data() {
    return {
      voucherId: 0,
      company: {},
      file: "",
      voucher: {
        date: "",
        toCustomer: {
          customerName: ""
        },
        way: [
          {
            forPerson: "",
            pass: 0
          }
        ]
      }
    };
  },
  mounted: function() {
    let voucherId = this.$route.query.voucherId;
    if (voucherId == undefined || voucherId == null) {
      return;
    }
    this.voucherId = Number(voucherId);
    if (this.voucherId != 0) {
      this.getVocuherCashbackForPrint(this.voucherId);
      this.getCompanyInfo();
    }
  },
  methods: {
    getVocuherCashbackForPrint: function(voucherId) {
      voucherService.getVocuherCashbackForPrint(voucherId).then(response => {
        this.voucher = JSON.parse(JSON.stringify(response));
        var expendRow = 4 - this.voucher.way.length;
        for (var i = 0; i < expendRow; i++) {
          this.voucher.way.push({});
        }
      });
    },
    printSaleVoucher: function() {
      window.print();
    },
    // account
    getCompanyInfo: function() {
      companyInfoService.getCompanyInfo().then(response => {
        this.company = Object.assign({}, response);
        this.file = `${axios.defaults.baseURL}/companyLogo/${response.logo}`;
      });
    }
  },
  filters: {
    perssengerCount: function(person) {
      if (person < 9) return "0" + person;
      return person;
    }
  }
};
</script>
<style scoped>
@font-face {
  font-family: "Handage";
  src: url("/font/HANDA__.woff") format("woff");
  src: url("/font/HANDA.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Haettenschweiler";
  src: url("/font/Haettenschweiler.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.heading-font {
  font-family: "Handage";
  letter-spacing: 0.3em;
}
.first-heading-font {
  font-family: "Haettenschweiler" !important;
  font-weight: 300;
  letter-spacing: 0.2em !important;
}
.vertical-dotted-divider {
  border-top: 2px dotted black;
}
.hori-dotted-divider {
  border-left: 2px dotted black;
}
.vertical-divider {
  border-top: 2px solid black;
}
.underlineVoucher {
  text-decoration: underline;
}
.simpleTable td,
.simpleTable th {
  border: 1px solid black;
  border-radius: 7px;
  /* white-space: pre-wrap; */
  padding-left: 10px;
  padding-right: 10px;
}
.noBorder {
  border: 0px !important;
}
.innerTable {
  border-collapse: collapse;
  border-style: hidden;
}
.innerTable td,
.innerTable th {
  border: 1px solid black;
}
.innerTable tr:first-child th {
  border-top: 0px;
}
.innerTable tr:last-child td {
  border-bottom: 0;
}
.innerTable tr td:first-child,
.innerTable tr th:first-child {
  border-left: 0;
}
.innerTable tr td:last-child,
.innerTable tr th:last-child {
  border-right: 0;
}

/* td::before { */
/* content: " "; */
/* } */
</style>
