import BasicService from "./BasicService";

class CompanyInfoService extends BasicService {
    constructor() {
        super();
    }
getCompanyInfo() {
    let url = `/companyProfile`;
    return this.axios.get(url).then(request => request.data);
}

updateCompanyProfile(formDate, id) {
    let url = `/companyProfile/${id}`;
    return this.axios.put(url, formDate,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(request => request.data);
}

getCompanyImage(name){
    let url = `/companyLogo/${name}`
    return this.axios.get(url).then(request => request.data);
}
}

const companyInfoService = new CompanyInfoService();
export default companyInfoService;